(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

;
(function () {
  var $btn = $('#go-top');
  $(window).scroll(function (event) {
    if ($(window).scrollTop() >= $(window).height() / 2) {
      $btn.addClass('show');
    } else {
      $btn.removeClass('show');
    }
  }).scroll();
  $btn.click(function () {
    $('body,html').stop().animate({
      scrollTop: 0
    }, 500);
    return false;
  });
})();

},{}],2:[function(require,module,exports){
"use strict";

var $pushmenu;
$(".m_nav").pushmenu({
  button: "#nav_toggle"
});
$pushmenu = $('.m_nav').data('pushmenu');
$("#m-nav li").each(function () {
  var sub = $(this).find(".sub-menu");
  var link = sub.find("a").length > 0;
  if (sub && link) {
    $(this).addClass("has-sub");
    $(this).children("a").append('<i class="iconfont icon-db-arrow"></i>');
  }
});
$(".m-nav-list > li > a").click(function () {
  var sub = $(this).next(".sub-menu");
  if (!$(this).parents("li").hasClass("has-sub")) {
    return true;
  }
  if (sub.is(":visible")) {
    sub.slideUp();
    $(this).removeClass("on");
  } else {
    $("#m-nav .sub-menu").slideUp();
    $("#m-nav li .on").removeClass("on");
    $(this).addClass("on");
    sub.slideDown();
  }
  return false;
});

// 锚点点击关闭mask
$('#m-nav .sub-menu a').click(function (e) {
  if (this.hash && $(this.hash).length > 0) {
    var offset = $('.m_header').height();
    var Scroll = $(this.hash).offset().top - offset;
    $('html,body').stop(false, true).animate({
      'scrollTop': Scroll + 'px'
    }, 400);
    $('#nav_toggle').click();
  }
});
$('.sub-menu').each(function () {
  $(this).find('li').each(function () {
    var $third = $(this).find('.m-third-nav');
    if ($third.length) {
      $(this).children('a').append('<i class="iconfont icon-down"></i>');
      $(this).addClass('have-third');
    }
  });
});
$(".sub-menu li > a").click(function () {
  var sub = $(this).next(".m-third-nav");
  if (!$(this).parents("li").hasClass("have-third")) {
    return true;
  }
  if (sub.is(":visible")) {
    sub.slideUp();
    $(this).removeClass("on");
  } else {
    $("#m-nav .m-third-nav").slideUp();
    // $("#m-nav li .on").removeClass("on")
    $(this).addClass("on");
    sub.slideDown();
  }
  return false;
});

/*$("#m-nav .sub-nav a").click(function () {
    $pushmenu.hideMenu();
});
*/

},{}],3:[function(require,module,exports){
"use strict";

require("./_go-top.js");
require("./_m-nav.js");

},{"./_go-top.js":1,"./_m-nav.js":2}]},{},[3]);
